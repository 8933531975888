<template>
  <div id="menus-list-main">
    <!-- <NForm inline label-placement="left">
    <NFormItem label="搜索关键词：">
      <NInput v-model:value="reqParams.menu_name" />
    </NFormItem>
    <NFormItem label="状态：">
      <div style="width: 200px;">
        <NSelect :options="statusOptions" v-model:value="reqParams.status"></NSelect>
      </div>
    </NFormItem>
    <NFormItem>
      <NSpace>
        <NButton type="primary" @click="MenuSearch">搜索</NButton>
        <NButton @click="handleClear">清除</NButton>
      </NSpace>
    </NFormItem>
  </NForm> -->
  <div class="addedit-title">{{Title}}</div>
  <div class="addedit-bor"></div>
  <div class="addedit-btns">
    <NButton type="primary" class="menus-list-add" @click="sendMail('',2)">新增</NButton>
  </div>
  <NDataTable :columns="tableColumns" :data="tableData" :row-key="(row) => row.id" />

  <PageLoading :loading="loading" />
  </div>
</template>

<script setup>
  import { ref, h } from 'vue';
  import { NSpace, NDataTable, NButton, NForm, NFormItem, NInput, NSelect, NImage, useMessage } from 'naive-ui';
  import { resStatusEnum } from '@/enumerators/http.js';
  import { GetSchoolTypeMenus, PostSchoolTypeDelete, PostSchoolTypeStatus } from '@/api/school-type.js'; // 列表 删除 更改状态 
  import accountType from "@/enumerators/account-admin";
  import { toRaw } from '@vue/reactivity'
  import PageLoading from "@/components/PageLoading/index.vue";
  import { useRouter, useRoute } from 'vue-router';
  import ownUrlPermission from '@/utils/own-url-permission.js';

  const { SUCCESS, ERROR } = resStatusEnum;
  const message = useMessage();
  const loading = ref(false);
  const route = useRouter();
  const router = useRoute();
  const otherId = router.query.id;
  const Title = router.query.title;
  
  const tableColumns = [
    { title: '菜单名称', key: 'menu_name' },
    { title: 'URL', key: 'menu_url' },
    { title: '图标', key: 'file_url',render (row) {
        return h(
          NImage,
          {
            src: row.file_url,
            style:{width:'40px',height:'40px',overflow:'hidden'}
          }
        )
      }
    },
    { title: '状态', key: 'status',render: (row) =>
      h("span", null, { default: () => getResourceName(row.status) })
    },
    { title: '等级', key: 'level',render: (row) =>
      h("span", null, { default: () => getResourceName1(row.level) })
    },
    { title: '排序', key: 'iorder' },
    { title: '备注', key: 'remark' },
    {
      title: '操作',
      render: (row) => h(
        NSpace,
        null,
        {
          default: () => {
          let tempArr = [];
          let arr1 = h(NButton, { text: true, type: 'primary',onClick: () => sendMail(row,1)}, { default: () => '编辑' })
          let arr2 = h(NButton, { text: true, type: 'primary',onClick: () => sendMail(row,0) }, { default: () => '添加子菜单' })
          let arr3 = h(NButton, { text: true, type: 'primary',onClick: () => showHide(row) }, { default: () =>{
            if(row.status=='1'){
              if(row.is_delete =='1'){
                return '隐藏'
              }
            }else{
             return '显示'
            }
          }  })
          let arr4 = h(NButton, { text: true, type: 'error',onClick: () => DeleteMenu(row) }, { default: () =>{
            if(row.is_delete =='1'){
             return '删除'
            }else{
             return false
            }
          }})
          
          // 编辑
          if (ownUrlPermission('school-type/menu-update')) {
            tempArr.push(arr1);
          }
          // 添加子菜单
          if (ownUrlPermission('school-type/menu-create')) {
            tempArr.push(arr2);
          }
          // 状态改变
          if (ownUrlPermission('school-type/menu-update-status')) {
            tempArr.push(arr3);
          }
          // 删除
          if (ownUrlPermission('school-type/menu-delete')) {
            tempArr.push(arr4);
          }
          return tempArr;
          }
        }
      ) 
    },
  ];

   const statusOptions = [
    {
      label: '显示',
      value: 1
    },
    {
      label: '隐藏',
      value: 0
    }
  ];

  // 常用的变量
  const reqParams = ref({
    menu_name: null,
    status: null
  });

  // 清除时的赋值
  const clearValue  = () => ({
    menu_name: null,
    status: null
  });


  const tableData = ref([]);

  // 数据初始化
  function onMounted(params) {
    loading.value = true;
    GetSchoolTypeMenus(params).then(res => {
      if (res.code === SUCCESS) {
        loading.value = false;
        tableData.value = res.data || [];
      }
    }).catch(err => {});
  }
  onMounted({"SchoolType[id]":otherId})

  // 菜单搜索
  function MenuSearch() {
    var SearchParameters = toRaw(reqParams)
    let params = {
      "SchoolType[id]":otherId,
      menu_name : SearchParameters.value.menu_name,// menu_name
      status: SearchParameters.value.status// status
      }
    // console.log("params",params)
    onMounted(params)
  }

  // 菜单显隐操作
  function showHide(row){
    loading.value = true;
    var showHideData = {}
    showHideData.id = row.id
    if(row.status == '1'){
      // 隐藏
      showHideData.status = 0
    }else{
      // 显示
      showHideData.status = 1
    }
    PostSchoolTypeStatus(showHideData).then(res => {
      if (res.code === SUCCESS) {
        loading.value = false;
        // 请求完成后 刷新数据
        message.info(res.msg);
        MenuSearch()
      }
    }).catch(err => {});
  }

  // 清除时
  function handleClear() {
    toRaw(reqParams).value.menu_name = null
    toRaw(reqParams).value.status = null
  }

  // 删除菜单
  function DeleteMenu(row) {
    loading.value = true;
    let params = {id:row.id}
    PostSchoolTypeDelete(params).then(res => {
      if (res.code === SUCCESS) {
        loading.value = false;
        message.info(res.msg);
        // 删除完成后 刷新数据
        MenuSearch()
      }
    }).catch(err => {});
  }

  // 编辑或添加时
  function sendMail(row,is) {
    // 编辑时1  添加子菜单0  新增2
    if(is==0){
      route.push({
        path:'/school-type/index/edit',
        query:{id:otherId,is,cid:row.id}
      })
    }else if(is==1){
      route.push({
        path:'/school-type/index/edit',
        query:{id:otherId,is,cid:row.id}
      })
    }else if(is==2){
      route.push({
        path:'/school-type/index/edit',
        query:{id:otherId,is}
      })
    }
  }

  // 状态
function getResourceName(status) {
  return accountType[status] || "";
}

  // 等级
function getResourceName1(status) {
  if(status=="1"){
    return "一级"
  }
  else if(status=="2"){
    return "二级"
  }
  else if(status=="3"){
    return "三级"
  }
  else if(status=="4"){
    return "四级"
  }
  else if(status=="5"){
    return "五级"
  }
  else if(status=="6"){
    return "六级"
  }
}

</script>

<style scoped>
#menus-list-main{
  box-sizing: border-box;
  padding: 10px 55px 50px 30px;
  background: #fff;
}
.menus-list-add{
  width:120px;
}
a{
  text-decoration: none;
}
#menus-list-main .addedit-title {
  font-size: 18px;
  color: #000;
  font-weight: 700;
  margin-top: 10px;
  margin-bottom: 10px;
}
#menus-list-main .addedit-bor {
  height: 1px;
  border-bottom: 1px solid #d7d7d7;
  position: absolute;
  left: 0;
  right: 0;
  top: 65px;
}
#menus-list-main .addedit-btns{
  margin-bottom: 20px;
  text-align: right;
  margin-top: 40px;
}
</style>